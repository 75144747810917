import React from 'react';
import logoImage from './logo.svg';
import theme from '../theme';

const FullPageLogo = () => (
    <div
        css={`
            background-color: ${theme.colors.primary};
            flex-grow: 1;
            height: 90vh;
            padding: 1em;
        `}
    >
        <div
            css={`
                background-image: url(${logoImage});
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                height: 100%;
                width: 100%;
            `}
        />
    </div>
);

export default FullPageLogo;
