import styled from 'styled-components';
import theme from '../theme';

const FollowUsLink = styled.a`
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    text-decoration: none;
    color: ${theme.colors.primary};
    margin: 0 auto;
    span {
        margin-left: 0.5rem;
    }
`;

export default FollowUsLink;
