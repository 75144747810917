import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { transparentize } from 'polished';
import Media from 'react-media';
import { translate } from 'react-polyglot';
import Icon from 'react-fa';
import styled from 'styled-components';

import theme from '../theme';

const StyledTabList = styled(TabList)`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
`;

const StyledTab = styled(Tab).attrs({
    selectedClassName: 'selected',
})`
    text-align: center;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem 0.5rem;
    width: 100%;

    @media (min-width: 960px) {
        padding: 2rem;
        width: 33.33333%;
    }
    transition: all 0.5s;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    cursor: pointer;

    & span {
        margin-top: 1rem;
    }

    & .fa {
        display: none;

        @media (min-width: 960px) {
            display: block;
        }
        margin-top: 0;
        transition: all 0.5s;
    }

    &:hover {
        background-color: ${transparentize(0.7, theme.colors.primary)};
    }

    &.selected {
        background-color: ${theme.colors.primary};

        & .fa {
            color: white;
        }
    }
`;

const StyledTabPanel = styled(TabPanel).attrs({
    selectedClassName: 'selected',
})`
    background-color: ${theme.colors.primary};

    & > div {
        max-width: 960px;
        margin: 0 auto;
    }

    &.selected {
        padding: 2em;
    }
`;

const AboutUs = ({ t }) => (
    <Media query="(min-width: 960px)">
        {matches =>
            matches ? (
                <Tabs>
                    <StyledTabList>
                        <StyledTab>
                            <Icon name="users" size={'5x'} />
                            <span>{t('title_about_us_who_are_we')}</span>
                        </StyledTab>
                        <StyledTab>
                            <Icon name="wrench" size={'5x'} />
                            <span>{t('title_about_us_expertise')}</span>
                        </StyledTab>
                        <StyledTab>
                            <Icon name="flag-checkered" size={'5x'} />
                            <span>{t('title_about_us_objectives')}</span>
                        </StyledTab>
                    </StyledTabList>

                    <StyledTabPanel>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t('content_about_us_who_are_we'),
                            }}
                        />
                    </StyledTabPanel>
                    <StyledTabPanel>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t('content_about_us_expertise'),
                            }}
                        />
                    </StyledTabPanel>
                    <StyledTabPanel>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t('content_about_us_objectives'),
                            }}
                        />
                    </StyledTabPanel>
                </Tabs>
            ) : (
                <>
                    <h3>{t('title_about_us_who_are_we')}</h3>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('content_about_us_who_are_we'),
                        }}
                    />
                    <h3>{t('title_about_us_expertise')}</h3>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('content_about_us_expertise'),
                        }}
                    />
                    <h3>{t('title_about_us_objectives')}</h3>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('content_about_us_objectives'),
                        }}
                    />
                </>
            )
        }
    </Media>
);

AboutUs.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate()(AboutUs);
