import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fa';
import { I18n, translate } from 'react-polyglot';
import 'react-tabs/style/react-tabs.css';
import { graphql } from 'gatsby';

import theme from '../../theme';
import withSetLocale from '../../components/withSetLocale';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import AboutUs from '../../components/aboutus';
import FullPageLogo from '../../components/fullpage-logo';
import Product from '../../components/product';
import FollowUsLink from '../../components/follow-us-link';
import Section from '../../components/section';
import SectionContent from '../../components/section-content';
import SectionTitle from '../../components/section-title';

const IndexPageView = ({ products, locale, t }) => (
    <Layout locale={locale}>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <FullPageLogo />
        <div
            css={`
                display: flex;
                flex-direction: column;
            `}
        >
            <Section>
                <SectionContent>
                    <SectionTitle>{t('title_our_creation')}</SectionTitle>
                    <div
                        css={`
                            display: flex;
                            flex-direction: column;

                            @media (min-width: 960px) {
                                flex-wrap: wrap;
                                flex-direction: row;
                            }

                            & > div {
                                padding: 0.25rem;

                                @media (min-width: 960px) {
                                    width: 33.33333%;
                                }
                            }
                        `}
                    >
                        {products.map(product => (
                            <Product key={product.title} product={product} />
                        ))}
                    </div>
                </SectionContent>
            </Section>
            <Section color={theme.colors.lightGrey}>
                <SectionContent>
                    <SectionTitle>{t('title_shop_section')}</SectionTitle>
                    <p
                        css={`
                            font-size: 1.25rem;
                            text-align: center;
                        `}
                    >
                        {t('description_shop_section')}
                    </p>
                    <p
                        css={`
                            text-align: center;
                        `}
                    >
                        <a
                            className="btn btn-primary btn-lg"
                            href="https://www.etsy.com/fr/shop/LesPattesNoires"
                            target="_blank"
                            rel="noopener noreferrer"
                            css={`
                                text-align: center;
                                text-decoration: none;
                                padding: 0.75rem 1.5rem;
                                font-size: 1.25rem;
                                border-radius: 0.3rem;
                                background-color: ${theme.colors.primary};
                                border-color: #f7c723;
                                box-shadow: inset 0 1px 0
                                        rgba(255, 255, 255, 0.15),
                                    0 1px 1px rgba(0, 0, 0, 0.075);
                            `}
                        >
                            {t('label_go_to_shop')}
                        </a>
                    </p>
                    <div
                        css={`
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            @media (min-width: 900px) {
                                flex-direction: row;
                                justify-content: space-between;
                            }
                        `}
                    >
                        <FollowUsLink href="https://www.instagram.com/lespattesnoires/">
                            <Icon name="instagram" />
                            <span>Instagram</span>
                        </FollowUsLink>
                        <FollowUsLink href="https://facebook.com/LesPattesNoires">
                            <Icon name="facebook" />
                            <span>Facebook</span>
                        </FollowUsLink>
                    </div>
                </SectionContent>
            </Section>
            <Section>
                <SectionContent>
                    <SectionTitle>{t('title_about_us')}</SectionTitle>
                    <AboutUs />
                </SectionContent>
            </Section>
        </div>
    </Layout>
);

IndexPageView.propTypes = {
    products: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

const TranslatedIndexPage = translate()(IndexPageView);

const getProductsFromData = ({ products }) =>
    products
        ? products.edges.map(p => ({
              title: p.node.frontmatter.title,
              image: p.node.frontmatter.image.childImageSharp.fluid,
          }))
        : [];

const IndexPage = ({ data, pageContext: { locale, messages } }) => (
    <I18n locale={locale} messages={messages}>
        <TranslatedIndexPage
            locale={locale}
            products={getProductsFromData(data)}
        />
    </I18n>
);

IndexPage.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default withSetLocale()(IndexPage);

export const query = graphql`
    query($locale: String!) {
        products: allMarkdownRemark(
            filter: { frontmatter: { lang: { eq: $locale } } }
            sort: { fields: [frontmatter___order], order: [ASC] }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        image {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
