import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Product = ({ product }) => (
    <div
        css={`
            position: relative;

            & img {
                transform: scale(1);
                transition: all 250ms ease-in !important;
            }

            &:hover {
                & img {
                    transform: scale(1.08);
                }
                & span {
                    opacity: 1;
                }
            }

            & span {
                background-color: rgba(255, 255, 255, 0.6);
                color: black;
                position: absolute;
                bottom: 2rem;
                width: 100%;
                text-align: center;
                font-weight: bold;
                opacity: 0;
                transition: all 250ms ease-in !important;
            }
        `}
    >
        <Img fluid={product.image} />
        <span>{product.title}</span>
    </div>
);

Product.propTypes = {
    product: PropTypes.shape({
        image: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
    }),
};

export default Product;
