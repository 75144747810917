import withContext from 'recompose/withContext';
import PropTypes from 'prop-types';

export default configuredLocale =>
    withContext(
        {
            locale: PropTypes.string.isRequired,
        },
        ({ pageContext }) => ({
            locale: configuredLocale || pageContext.locale,
        }),
    );
